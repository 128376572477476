.sidebarBS {
    width: 65px;
    border-radius: 20px 0 0 20px;
    background: #FFF;
    box-shadow: 0 4px 70px 0 rgba(0, 0, 0, 0.10);
    transition: ease-in-out width 0.5s;
    padding-top: 2rem;
    padding-right: 5px;
    padding-left: 5px;
    min-height: 750px;
    max-height: 750px;
    position: sticky;
    top: 5rem;
    margin-top: 1rem;
}

.sidebarBS a {
    color: #1A478F;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebarBS a {
    text-align: center;
}

.sidebarBS .nav-pills .nav-link {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
}

.sidebarBS .nav-pills .nav-link.active{
    background: #000;
    color: white;
    font-weight: 900;
    transition: ease-in-out all 0.5s;
}

.sidebarBS .menu-item_label, .sidebarBS .settings-button_label {
    display: none;
}

.sidebarBS.expanded {
    width: 250px;
    transition: ease-in-out width 0.5s;
}

.sidebarBS.expanded .menu-item_label, .sidebarBS.expanded  .settings-button_label {
    display: block;
}

.sidebarBS .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebarBS .nav-item {
    width: fit-content;
}

.sidebarBS.expanded .nav-item {
    width: 100%;
}

.sidebarBS.expanded .menu-item {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    font-weight: 400;
    font-size: 1rem;
    align-items: flex-end;
}

.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    border: 1px solid #1E599D;
    max-width: 130px;
}

.sidebar-header_title {
    color: #1E599D;
    text-align: right;
    font-family: Kumbh Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.sidebarBS .sidebar-header_icon{
    border-radius: 5px;
    background-color: #1A478F;
    color: white;
    width: fit-content;
    padding: 5px;
    margin: 0 auto;
}

.sidebarBS.expanded .sidebar-header_icon {
    background-color: #1A478F;
    color: white;
    padding: 5px;
    border-radius: 0 12px 12px 0;
    margin-right: 0;
}

.sidebarBS.expanded .menu-header {
    color: #1A478F;
    text-align: right;
    font-family: Kumbh Sans, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 27px 0;
}

.sidebarBS .menu-header {
    color: #1A478F;
    text-align: center;
    font-family: Kumbh Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0;
}

.sidebarBS.expanded .settings-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    padding: 10px 0;
}

.sidebarBS .nav-pills .nav-link.active .settings-button {
    color: white;
}

.sidebarBS .nav-pills .nav-link .settings-button {
    width: 100%;
}

.sidebarBS.expanded .nav-pills .nav-link .settings-button {
    color: #1A478F;
    width: 100%;
}

.sidebar-top-section {
    border-radius: 20px 0 0 20px;
    position: relative;
}

.sidebar-content {
    border-radius: 20px 0 0 20px;
    height: 90%;
}

.main-menu {
    color: #000;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.3;
    text-align: center;
    text-indent: 1rem;
}

.sidebarBS.expanded {
    font-size: 1.25rem;
    font-weight: 400;
    width: 250px;
    transition: ease-in-out width 0.5s;
}

.sidebar-nav-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.sidebarBS.expanded .main-menu {
    text-align: left;
    font-size: 1.25rem;
}

.sidebarBS.expanded .menu-item_label, .sidebarBS.expanded  .settings-button_label {
    display: block;
}

.sidebarBS .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebarBS.expanded .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: left;
    width: 100%;
}

.sidebarBS.expanded .settings-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    background: #F2F2F2;
    padding: 10px 0;
}

.expand-sidebar-icon {
    position: absolute;
    top: -5%;
    right: -85%;
    color: #000000;
}

.collapse-sidebar-icon {
    position: absolute;
    top: -5%;
    right: 0;
    color: #000000;
}


.mobileFooter {
    position: fixed;
    z-index: 999;
    bottom: 4%;
    width: 90%;
    background-color: #000000;
    display: flex;
    justify-content: space-evenly;
    border-radius: 2em;
    padding: 0.5rem 0;
    color: white;
    align-items: center;
    transform: translate(50%, 50%);
    right: 50%;
}

.mobileFooter .nav-link {
    padding: 0.5rem;
    height: 100%;
    border-radius: 1rem;
}

.mobileFooter .nav-link:hover {
    color: white;
    background-color: #444444;
}

.mobileFooter .nav-link.active {
    color: #000000;
    background-color: white;
}

.mobileFooter .dropdown-toggle {
    color: white;
}

.mobileFooter .dropdown-menu {
    background-color: #000000;
    width: fit-content;
    max-width: 6rem;
    min-width: 4rem;
}

.mobileFooter .dropdown-menu a.dropdown-item {
    color: white;
    padding: 0.5rem 0;
}

.mobileFooter .dropdown-menu a.dropdown-item:hover,
.mobileFooter .dropdown-menu a.dropdown-item:focus,
.mobileFooter .dropdown-item.active {
    background-color: #444444;
}

.mobileFooter .menu-item_label {
    display: none;
}

#dropdown-autoclose-true:before {
    content: unset;
}

.menu-item-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 70px; /* Set a fixed width or max-width for uniformity */
    padding: 10px;
}

.menu-icon-label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%; /* Ensure the container takes full height */
}

.icon-container {
    margin-bottom: 5px; /* Add space between icon and label */
}

.label-container {
    font-size: 12px;
    max-width: 80px; /* Set a fixed width for the label container */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mobileFooter.admin-footer {
    background-color: white;
    color: black;
}

.mobileFooter.admin-footer .nav-link.active {
    color: white;
    background-color: #000000;
}

.mobileFooter.admin-footer .dropdown-menu a.dropdown-item:hover,
.mobileFooter.admin-footer .dropdown-menu a.dropdown-item:focus,
.mobileFooter.admin-footer .dropdown-item.active {
    background-color: #444444;
}

.mobileFooter.admin-footer .dropdown-toggle{
    color: #000000;
}