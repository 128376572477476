.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    overflow: hidden;
}

.loading-icons-container {
    animation: rotateAndMoveAnimation 1s forwards;
    animation-delay: 0.5s;
}

/* Apply unique animation for each SVG */
.svg-icon-1 {
    animation: moveLeftAnimation 1s forwards infinite alternate, colorTransition1 0.5s ease-in-out;
    animation-delay: 1.5s;
    z-index: 10;
    position: relative;
}

.svg-icon-2 {
    animation: moveRightAnimation 1s forwards infinite alternate;
    animation-delay: 1.5s;
}

@keyframes rotateAndMoveAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
}

@keyframes moveLeftAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(48%);
    }
}

@keyframes moveRightAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-48%);
    }
}

/* Color transition for SVG 1 */
.svg-icon-1 svg linearGradient stop:first-of-type {
    stop-color: #92C020;
    transition: stop-color 0.5s ease-in-out;
}

/* Additional keyframe for color transition */
@keyframes colorTransition1 {
    0% {
        stop-color: #92C020;
    }
    100% {
        stop-color: white;
    }
}

.data-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.data-loader {
    width: 200px;
    height: 200px;
    border: 6px dotted #92C020;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}
.data-loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 6px dotted #92C020;
    border-style: solid solid dotted;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

:root {
    --animationColor: #92C020;
}

* {
    box-sizing: border-box;
}

@keyframes move-dot1 {
    0% {
        transform: translateX(-50%) translateY(-50%);
    }

    20% {
        transform: translateX(-50%) translateY(30%);
    }

    100% {
        transform: translateX(-50%) translateY(30%);
    }
}

@keyframes move-dot2 {
    0% {
        transform: translateX(-50%) translateY(-50%);
    }

    15% {
        transform: translateX(-50%) translateY(-10%);
    }

    30% {
        transform: translateX(-10%) translateY(-10%);
    }

    100% {
        transform: translateX(-10%) translateY(-10%);
    }
}

body {
    background: #eee;
}

.animation-example {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 50vmin;
    width: 50vmin;
    z-index: 999;
}

.animation-example > .item {
    position: absolute;
    top: 0;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translateX(-50%);
    transform-origin: 50% 100%;
    pointer-events: none;
}

.animation-example > .item:nth-child(2) {
    transform: translateX(-50%) rotate(90deg);
}

.animation-example > .item:nth-child(3) {
    transform: translateX(-50%) rotate(180deg);
}

.animation-example > .item:nth-child(4) {
    transform: translateX(-50%) rotate(270deg);
}

.animation-example > .item > .line {
    height: 100%;
    width: calc(50% + 1px);
    border-right: 2px dashed var(--animationColor);
}

.animation-example > .item > .dot {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    width: 100%;
    animation: move-dot1 5.5s linear infinite;
}

.animation-example > .item > .dot::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 5%;
    width: 5%;
    background: #fff;
    border: 2px solid var(--animationColor);
    border-radius: 50%;
}

.animation-example > .item > .circle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background: var(--animationColor);
}

.animation-example > .item.-type2 {
    top: 0;
    left: 0;
    transform: none;
    transform-origin: 100% 100%;
}

.animation-example > .item.-type2:nth-child(5) {
    transform: scaleX(-100%);
}

.animation-example > .item.-type2:nth-child(6) {
    transform: scaleX(-100%) scaleY(-100%);
}

.animation-example > .item.-type2:nth-child(7) {
    transform: scaleY(-100%);
}

.animation-example > .item.-type2 > .line {
    position: absolute;
    top: 30%;
    left: calc(30% - 1px);
    width: 70%;
    height: 40%;
    border-right: none;
    border-left: 2px dashed var(--animationColor);
    border-bottom: 2px dashed var(--animationColor);
}

.animation-example > .item.-type2 > .dot {
    top: 30%;
    left: 30%;
    animation: move-dot2 4s linear infinite;
}

.animation-example > .item.-type2 > .circle {
    top: 30%;
    left: 30%;
    transform: translateX(-50%) translateY(-50%);
}

.animation-example > .item:nth-of-type(1) > .dot {
    animation-delay: 0;
}

.animation-example > .item:nth-of-type(7) > .dot {
    animation-delay: .5s;
}

.animation-example > .item:nth-of-type(2) > .dot {
    animation-delay: 1s;
}

.animation-example > .item:nth-of-type(6) > .dot {
    animation-delay: 1.5s;
}

.animation-example > .item:nth-of-type(3) > .dot {
    animation-delay: 2s;
}

.animation-example > .item:nth-of-type(5) > .dot {
    animation-delay: 2.5s;
}

.animation-example > .item:nth-of-type(4) > .dot {
    animation-delay: 3s;
}

.animation-example > .item:nth-of-type(8) > .dot {
    animation-delay: 3.5s;
}

.animation-example > .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 50%;
    width: 50%;
}

.animation-example > .center > .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
}

.animation-example > .center > .circle:nth-child(1) {
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 3vmin rgba(0, 0, 0, .1);
}

.animation-example > .center > .circle:nth-child(2) {
    height: 80%;
    width: 80%;
    border: 2px solid var(--animationColor);
}

.animation-example > .center > .circle:nth-child(3) {
    height: 50%;
    width: 50%;
    background: var(--animationColor);
}
