.header-container {
    top: 0;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: #000;
    padding: 10px 20px;
    z-index: 100;
    position: sticky;
}

.search-box {
    width: 25%;
}

.search-box_input input {
    border-radius: 28px;
    background: #FFF;
}

.profile-picture {
    width: 2.5rem;
    border-radius: 28px;
    border: 2px solid #92C020;
}

.search-box_input {
    position: relative;
}

.search-box_input .search-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    background: linear-gradient(252deg, #92C020 6.99%, #CAD511 87.97%);
    padding: 6px;
}

.profile-dropdown-container .dropdown-toggle, .profile-dropdown-container .btn.show, .profile-dropdown-container .btn:hover {
    background-color: unset;
    border: unset;
}

.profile-dropdown-button.dropdown-toggle:after {
    display: none;
}

.profile-dropdown-container .profile-dropdown-menu {
    transform: translate(0%, 15%) !important;
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.10);
    min-width: 192px;
}

.profile-dropdown-menu p {
    color: #000;
    font-size: 1rem;
    font-weight: 700;
    margin: 5px 0;
}

.pricing-button {
    color: white;
    border: unset;
    padding: 3px 10px;
    border-radius: 29px;
    background: linear-gradient(259deg, #92C020 -4.26%, #CAD511 101.68%);
    font-size: 0.75rem;
    font-weight: 700;
}

.profile-picture-dropdown {
    width: 3.625rem;
    border-radius: 50%;
    border: 2px solid #92C020;
}

.new-client-btn {
    border-radius: 25px;
    background: linear-gradient(265deg, #92C020 -23.12%, #CAD511 103.2%);
    border: unset;
    width: fit-content;
}

.new-client-btn.dropdown-toggle:after {
    display: none;
}

.notification-dropdown {
    color: white;
    position: relative;
}

.notification-dropdown button {
    background-color: transparent;
    border: unset;
    padding: 0;
}

.notification-dropdown button:after, .notification-dropdown.dropstart .dropdown-toggle::before {
    display: none;
}

.notification-dropdown button:hover, .notification-dropdown button:focus {
    background-color: transparent;
    border: unset;
}

/*.notification-dropdown:after {*/
/*    content: ".\2022";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    background: linear-gradient(#92C020, #CAD511);*/
/*    border-radius: 50%;*/
/*    width: 50%;*/
/*    font-size: 8px;*/
/*}*/

.notification-dropdown .dropdown-toggle .notification-badge {
    position: absolute;
    top: -20%;
    right: -20%;
    width: 17px;
    height: 17px;
    font-size: 10px !important;
    background: linear-gradient(#92C020, #CAD511);
    padding: 5px 0 0;
}

.notifications-menu {
    display: flex;
    width: 471.07px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.notification-item {
    line-height: 40px;
    height: 50px;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 4px;
    background: rgba(146, 192, 32, 0.12);
}

.notification-item span {
    /*box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10);*/
    /*border-radius: 10px;*/
    padding: 10px 15px;
}

/*.notification-item.dropdown-item:hover {*/
/*    color: white;*/
/*    background-color: #92C020;*/
/*}*/

.notification-item.dropdown-item .spark-icon {
    color: #92C020;
}

.notification-item.dropdown-item:hover .spark-icon {
    color: #FFFFFF;
}

.empty-placeholder {
    min-width: 300px;
    margin: 20px;
}

@media only screen and (max-width: 768px) {
    .search-box {
        width: 40%;
    }
}

.notification-item.dropdown-item:focus, .notification-item.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.greenDot {
    padding: 1rem 0 0 0;
}

.greenDot:after {
    content: " \2022";
    font-size: 4rem;
}