.integration-modal {
    backdrop-filter: blur(8px);
}

.integration-modal .modal-content {
    min-height: 250px;
    justify-content: center;
}

.centered-modal-title {
    background: linear-gradient(180deg, #92C020 0%, #CAD511 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
    font-size: 3rem;
}

.centered-modal-description {
    color: #666;
    font-size: 1rem;
    font-weight: 400;
}

.congratulations-animation {
    position: absolute;
    top: 0;
    transform: scale(4);
}

.modal-90w {
    max-width: none !important;
    width: 90%;
}

.dark-modal .modal-content{
    border: 1px solid #444;
    background: #242424;
    color: white;
}