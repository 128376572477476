.mainColor {
  color: #92C020;
}

.icon-hover-success {
  color: #92C020;
}

.icon-hover-success {
  color: #92C020;
  border-radius: 0 0 17px 0;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);
}

.icon-hover-success:hover {
  color: white;
  background-color: #92C020;
  transition: all 0.3s ease-in-out;
}

.mainBgColor {
  background-color: #92C020 !important;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: local('Kumbh Sans'), url(./assets/fonts/KumbhSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

div,h1,h2,h3,h4,h5,p,small{
  font-family: Kumbh Sans, sans-serif;
}

body {
  background: #EBEBEB !important;
}

.content-container {
  border-radius: 14px;
  border: 2px solid #FFF;
  background: #FFF;
  box-shadow: 0 4px 60px -7px rgba(0, 0, 0, 0.10);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.singIn-singOut-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

a {
  text-decoration: none !important;
}

.required:after {
  content:" *";
  color: red;
}

.grey-suit {
  color: #848199;
}

.bg-grey-suit {
  background-color: rgb(229, 229, 229) !important;
}

.checkbox-success input[type="checkbox"]:checked {
    background-color: #92C020;
    border-color: #92C020;
}

.checkbox-success input[type="checkbox"]:focus {
  border-color: #92C020;
  box-shadow: 0 0 0 0.2rem rgba(146, 192, 32, 0.25);
}

input[type="password"] {
  font:small-caption;
  font-size:16px;
}


.mainScroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.mainScroll::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

.mainScroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #FFF;
  background-image: -webkit-gradient(linear,
  40% 0%,
  75% 84%,
  from(#4D9C41),
  to(#19911D),
  color-stop(.6,#54DE5D))
}

.flex-centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-tabs.nav-underline .nav-link.active, .admin-tabs.nav-underline .show>.nav-link {
  color: #FFF;
  border-bottom: 2px solid #92C020;
}

.admin-tabs.nav-underline .nav-link, .admin-tabs.nav-underline .show>.nav-link {
  color: #888;
  border-bottom: unset;
}

.gradient-border {
  --border-width: 3px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #222;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
            60deg,
            hsl(224, 85%, 66%),
            hsl(269, 85%, 66%),
            hsl(314, 85%, 66%),
            hsl(359, 85%, 66%),
            hsl(44, 85%, 66%),
            hsl(89, 85%, 66%),
            hsl(134, 85%, 66%),
            hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

button.apply-btn {
  border-radius: 39px;
  border: 1px solid #DFDFDF;
  background: #92C020;
  color: #FFF;
  font-size: 1rem;
  font-weight: 500;
}

button.clear-btn {
  border-radius: 39px;
  background: rgba(146, 192, 32, 0.20);
  color: #92C020;
  font-size: 01rem;
  font-weight: 500;
  border: 1px solid #DFDFDF;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.submit-btn {
  border: 1px solid #92C020;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  border-radius: 30px;
  background: linear-gradient(263deg, #92C020 -9.91%, #CAD511 128.31%);
  transition: transform 80ms ease-in;
  cursor: pointer;
  width: fit-content;
  border: unset;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

}

.btn.btn-primary:active, .btn.btn-primary:focus, .btn.btn-primary.show, .btn.btn-primary:hover {
  background-color: #92C020;
  border-color: #4D9C41;
  color: #fff;
}

.border-right-dark {
  border-right: 1px solid black;
}

.border-left-dark {
  border-left: 1px solid black;
}

.doughnut-team-chart .chartjs-tooltip , .bar-team-chart .chartjs-tooltip, .line-team-chart .chartjs-tooltip, .doughnut-lead-chart .chartjs-tooltip, .line-sales-chart .chartjs-tooltip, .line-opportunities-chart .chartjs-tooltip, .doughnut-opportunities-chart .chartjs-tooltip, .sales-bar-chart .chartjs-tooltip {
  position: absolute;
  background-color: white;
  color: black;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid white;
  pointer-events: none;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 999;
  transition: ease-in-out all 0.25s;
}

.admin-theme .line-opportunities-chart .chartjs-tooltip {
  background-color: rgba(192,192,192,0.2);
  border: unset;
  padding: unset;
}

.admin-theme .line-opportunities-chart .chartjs-tooltip table {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0.5rem;
}

.admin-theme .line-opportunities-chart .chartjs-tooltip table .chartjs-tooltip-body{
  padding: 0.5rem;
}


.chartjs-tooltip table {
  padding: 1rem;
}

.lead-chart-container {
  max-width: 400px;
}

.team-chart-container {
  max-width: 400px;
}

.admin-theme.content-container {
  border: 1px solid #444;
  background: #242424;
}

.labels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*margin-top: 20px;*/
}

.label-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  border-right: 1px solid #ddd;
}

.social-icon-container svg {
  max-width: 21px;
  max-height: 21px;
}

.dark-mode .pagination-title {
  color: white;
}

.page-title {
  margin: 2rem 0;
  text-align: left;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.text-container-truncated {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust the line height based on your design */
  max-height: calc(4 * 1.5em); /* 4 lines with the same line height */
}

.pointer {
  cursor: pointer;
}

.searchBadgeRight {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  align-content: center;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}